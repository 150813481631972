@import url(https://fonts.googleapis.com/css?family=Baloo+Thambi+2&display=swap);
html  body {
  background: rgb(235, 235, 240);
  font-family: 'Baloo Thambi 2', cursive;
}

table {
  font-family: 'Baloo Thambi 2', cursive;

}
.custom-card-shadow {
  background: white;
  box-shadow: 10px 10px 51px -35px rgba(0,0,0,0.75);
}

.min-dimension-card {
  min-width: 210px !important;
  max-width: 210px !important;
  min-height: 100px !important;

}

.map {
  border : 1px solid lightgray;
  border-radius: 10px;
}

.table tbody {
  display: block;
  max-height: 60vh;
  overflow-y: scroll;
}


.table thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  background: white;
  box-shadow: 10px 10px 51px -35px rgba(0,0,0,0.75);
}
table {
  text-align: center;
}
table thead tr th {
  border: none !important;
}

thead {
  background: #d6d6d6;
}

.dashboard-title{
  font-size: 25px;
  line-height: 18px;
}

.credit-title{
  font-size: 10px;
}

.additional-count {
  font-size : 12px;
}

.main-logo {
    -webkit-animation:spin 10s linear infinite;
    animation:spin 10s linear infinite;
}


.blinking{
  -webkit-animation:blinkingText 1s infinite;
          animation:blinkingText 1s infinite;
}
@-webkit-keyframes blinkingText{
  0%{     color: #000   }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color: transparent;  }
  100%{   color: #000;    }
}
@keyframes blinkingText{
  0%{     color: #000   }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color: transparent;  }
  100%{   color: #000;    }
}

.mobile-cards {
  display: none !important;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { transform:rotate(360deg); } }

@media only screen and (max-width: 420px) {
  .card-div {
    margin-left: 16% !important;
  }
  html {
    font-size: 10px;
  }
  .country-cell {
    width : 100px;
  }

  .responsive-cards {
    display: none !important;
  }

  .mobile-cards {
    display: flex !important;
  }

  .additional-count {
    font-size : 8px !important;
  }

  .mobile-card-shadow {
    box-shadow: 0px 1px 5px -2px rgba(0,0,0,0.75);
    
  }
}

